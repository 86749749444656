import * as React from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import OrderGrid from "./OrderGrid";

const fieldStyle = {
    my:2,
    width: 350
}

export default function OrderList() {
    return (
        <Box
            component="form"
            sx={{ m: 1}}
            noValidate
            autoComplete="off"
        >
            <OrderGrid/>
        </Box>
    );

}

