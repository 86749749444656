import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";

/**
 * Renders a sign out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/",
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    };

    return (
            <Button variant="contained" color="info" size="small" onClick={() => handleLogout("redirect")}>
                Sign out
            </Button>
    );
};