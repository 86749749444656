import * as React from 'react';
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {useProducts} from "./ProductsDataProvider";
import {Modal} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddProduct from "./AddProduct";
import {Link, useNavigate} from "react-router-dom";
import {Download, Edit, Mail} from "@mui/icons-material";
import {useMsal, useMsalAuthentication} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {fetchData} from "../fetch";
import {loginRequest} from "../authConfig";
import EmailOrder from "./EmailOrder";

export default function OrderGrid() {

    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [token,setToken] = useState('');

    const [defaultEmail,setDefaultEmail] = useState('');

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const usdPrice = {
        type: 'number',
        width: 30,
        valueFormatter: ({ value }) => currencyFormatter.format(value===undefined?0: value),
        cellClassName: 'font-tabular-nums',
    };

    const [rows, setRows] = React.useState(() => [
    ]);

    useEffect(() => {
        console.log("Loading Order Grid");
        let ignore = false;
            const getData = async (token) => {
                try {
                    console.log("using token " + token);
                    const emailResponse = await fetchData(
                        '/defaultEmail', token
                    );
                    console.log(emailResponse);
                    setDefaultEmail(emailResponse.addr);
                    const orders = await fetchData(
                        '/Orders', token
                    );
                    console.log("Reponse is " + orders);
                        let actualData = orders;
                        console.log(actualData);
                        for (const orderItem of actualData) {
                            let id = orderItem.id;
                            let orderId = orderItem.orderNo;
                            let customerName = orderItem.customer.description;
                            let deliveryDate = orderItem.deliveryDate;
                            let totalCost = orderItem.totalCost;


                            setRows((prevRows) => [...prevRows, {
                                id: id,
                                customer: customerName,
                                orderNo: orderId,
                                deliveryDate: deliveryDate,
                                orderCost: totalCost,
                                notes: ''
                            }]);
                    }

                } catch (err) {
                    console.log(err);
                } finally {
                }
            }
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                console.log("Right from the source" + response.accessToken);
                console.log("Before set " + token );
                getData(response.accessToken);
                console.log("After set " + token );
            });
        return () => { ignore = true};
    }, [])

    const columns = [
        { field: 'id', headerName: 'Id', type:"string", width: 10, editable: false },
        { field: 'customer', headerName: 'Customer', type:"string", width: 100, editable: false },
        { field: 'orderNo', headerName: 'Order Number', type:"string", width: 100, editable: false },
        { field: 'deliveryDate', headerName: 'Delivery Date', type: 'string', width: 100, editable: false},
        {
            field: 'orderCost',
            headerName: 'Order Price',
            ...usdPrice,
            width: 60,
            editable: false
        },
        {
            headerName:'Edit',
            field: 'actions',
            width: 25,
            renderCell: (params) => <Link to={'/EditOrder?id='+params.row.id + '&isFuelOnly=' +params.row.orderNo.startsWith("F")} ><Edit/></Link>
        },
        {
            headerName:'PDF',
            field: 'pdf',
            width: 25,
            renderCell: (params) => <Link  target="_blank" rel="noopener noreferrer" to={process.env.REACT_APP_API_ENDPOINT + '/testTemplate/'+params.row.id } ><Download/></Link>
        },
        {
            headerName:'Email',
            field: 'email',
            width: 25,
            renderCell: (params) => <EmailOrder defaultEmail={defaultEmail} url={'/emailOrder/'+params.row.id } ></EmailOrder>
        }    ];



    const [idCounter,setIdCounter] = useState(0);

    return (
        <div style={{ height: 250, width: '100%', padding:4}}>
            <DataGrid editMode="row" rows={rows} columns={columns}  initialState={{
                columns: {
                    columnVisibilityModel: {id:false}}}}/>
            <Button sx={{ m: 1}} variant="contained" component={Link} to="/AddOrder?isFuelOnly=true" >Add Fuel Order</Button>
            <Button sx={{ m: 1}} variant="contained" component={Link} to="/AddOrder?isFuelOnly=false" >Add Warehouse Order</Button>
        </div>
    );


}


