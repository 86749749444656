import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useProducts} from "./ProductsDataProvider";
import {
    FormControl,
    FormHelperText,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    setRef,
    TextareaAutosize
} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {useMsal, useMsalAuthentication} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {fetchData} from "../fetch";
import {loginRequest} from "../authConfig";
import {Mail} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const style = {
    position: 'absolute',
    top: '25%',
    left: '25%',
    transform: 'translate(-25%, -25%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    rowSpacing :2
};

const fieldStyle = {
    my:2,
    width: 300
}

export default function EmailOrder(props) {
    const url = props.url;
    const [accessToken, setAccessToken] = useState();
    const [open, setOpen] = useState(false);
    const email = useRef(props.defaultEmail);
    const { instance, accounts } = useMsal();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }

    const sendEmail = () => {
        fetchData(url+"?toAddr=" + email.current.value,accessToken).then(alert("Email Sent!"),handleClose());
    };

    useEffect(() =>{
        if(accessToken == null){
            instance.acquireTokenSilent({
                forceRefresh: true,
                ...loginRequest,
                account: accounts[0],
            })
                .then((response) => {
                    setAccessToken(response.idToken);
                });
        }
        console.log("Ref: " + email.current);
        console.log("Default: " + props.defaultEmail);
    }, [accessToken]);

    return (
        <div>
            <Box sx={{m:1}} textAlign="center">
            <IconButton variant="text" size="small" onClick={handleOpen}>
                <Mail/>
            </IconButton>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TextField sx={fieldStyle} id="email" label="Email" defaultValue={props.defaultEmail} inputRef={(c) =>{setRef(email,c)}} type="string" variant="outlined"/>
                    <Button
                        variant="contained"
                        sx={fieldStyle}
                        onClick={() => {sendEmail()}}
                    >Email</Button>
                    <Button
                        variant="contained"
                        sx={fieldStyle}
                        onClick={() => {handleClose()}}
                    >Cancel</Button>
                </Box>
            </Modal>
        </div>
    );
}