import * as React from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from "@mui/material/TextField";
import {
    FormControlLabel,
    Grid,
} from "@mui/material";
import Button from "@mui/material/Button";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddProduct from "./AddProduct";
import {fetchData, updateData} from "../fetch";
import {useMsal, useMsalAuthentication} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {loginRequest} from "../authConfig";

const fieldStyle = {
    my:2,
    width: 175
}

const fieldStyleDoubleWidth = {
    my:2,
    width: 350
}

const currentDate = dayjs();
const tomorrow = dayjs().add(1, 'day');


export default function Order() {
    const [persistedLocation,setPersistedLocation] = useState('');
    const [loaded,setLoaded] = useState(false);
    const [submitDisabled,setSubmitDisabled] = useState(false);
    const [submitText,setSubmitText] = useState('Submit Order');
    const [termsText,setTermsText] = useState('');
    const [orderId,setOrderId] = useState('');
    const [customer,setCustomer] = useState('');
    const [customerInputValue,setCustomerInputValue] = useState('');
    const [location,setLocation] = useState('');
    const [orderNotes,setOrderNotes] = useState('');
    const [deliveryNotes,setDeliveryNotes] = useState('');
    const [poNo,setPoNo] = useState('');
    const [customerErrorText,setCustomerErrorText] = useState("");
    const [locationErrorText,setLocationErrorText] = useState("");
    const [origin,setOrigin] = useState('');
    const [originErrorText,setOriginErrorText] = useState("");
    const [deliveryDate,setDeliveryDate] = useState(tomorrow);
    const [orderDate,setOrderDate] = useState(currentDate.format('MM/DD/YYYY'));
    const [orderItems,setOrderItems] = useState([]);
    const [quote,setQuote] = useState(false);
    const [update,setUpdate] = useState(false);
    const [customersFetched,setCustomersFetched] = useState(false);
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [accessToken,setAccessToken] = useState();
    const quoteLabel = { inputProps: { 'aria-label': 'Quote?' } };
    const [customers, setCustomers] = useState([]);
    const [locations, setLocations] = useState([]);
    const origins = [{label:'Navasota', id: 'NAVASOTA'},{label:'Harlingen', id:'HARLINGEN'},{label:'Costa Rica', id:'COSTARICA'}];

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const usdPrice = {
        type: 'number',
        width: 130,
        valueFormatter: ({ value }) => currencyFormatter.format(value===undefined?0: value),
        cellClassName: 'font-tabular-nums',
    };

    const [queryParameters] = useSearchParams();

    const [isFuelOnly,setIsFuelOnly] = useState(String(queryParameters.get("isFuelOnly")));

    const [rows, setRows] = React.useState([]);
    const columns = [
        { field: 'wasLoaded', headerName: 'Loaded', type:"string", width: 100, editable: false, disableColumnFilter:true, disableColumnMenu: true },
        { field: 'product', headerName: 'Product', type:"string", width: 100, editable: false, disableColumnFilter:true, disableColumnMenu: true },
        { field: 'productPackage', headerName: 'Package', type: 'string', editable: false, disableColumnFilter:true, disableColumnMenu: true},
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            width: 75,
            editable: true,
            disableColumnFilter:true,
            disableColumnMenu: true
        },
        {
            field: 'price',
            headerName: 'Unit Price',
            ...usdPrice,
            editable: true,
            disableColumnFilter:true,
            disableColumnMenu: true
        },
        {
            field: 'totalCost',
            headerName: 'Total Price',
            ...usdPrice,
            valueGetter: (params) => (params.row.price !== undefined && params.row.quantity !== undefined)?params.row.price * params.row.quantity:0,
            editable: false,
            disableColumnMenu: true,
            disableColumnFilter:true
        },
        { field: 'note', headerName: 'Notes', type:"string", width: 100, editable: true, disableColumnMenu: true },
        {
            field: 'actions',
            type: 'actions',
            width: 50,
            disableColumnMenu: true,
            disableColumnFilter:true,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={deleteProduct(params.id)}
                />]
        }
    ];

    const [idCounter,setIdCounter] = useState(0);
    const handleAddRow = (currency,pack,product,quantity,note,handleClose) => {
        handleClose();
        console.log(isFuelOnly?"Gallons":product.label);
        console.log(isFuelOnly);
        console.log(product.label);
        setIdCounter(idCounter + 1);
        setRows((prevRows) => [...prevRows, { id: idCounter,product: product.label, productPackage: (isFuelOnly === "true")?"Gallons":pack.label, quantity:quantity, price:currency, totalCost:quantity*currency,note:note}]);
        setOrderItems((prevRows) => [...prevRows, { id: idCounter,product: product, productPackage: (isFuelOnly === "true")?null:pack, quantity:quantity, price:currency, totalCost:quantity*currency,note:note}]);
    };

    const processUpdatedRow =
        (updatedRow,originalRow) =>
        {
                    for (let i = 0; i < orderItems.length; i++) {
                        console.log(orderItems[i]);

                        if (orderItems[i].id === updatedRow.id) {
                            console.log(updatedRow);
                            orderItems[i].quantity = updatedRow.quantity;
                            orderItems[i].price = updatedRow.price;
                            orderItems[i].totalCost = updatedRow.totalCost;
                            orderItems[i].note = updatedRow.note;
                            console.log(orderItems[i]);
                            return updatedRow;
                        }
                    }
        };

    const handleProcessRowUpdateError = React.useCallback((error) => {
        alert(error.message);
    }, []);

    const deleteProduct = React.useCallback(
        (id) => () => {
            setTimeout(() => {
                setRows((prevRows) => prevRows.filter((row) => row.id !== id));
            });
        },
        [],
    );

    useEffect(() => {
        if(accessToken == null){
            instance.acquireTokenSilent({
                forceRefresh: true,
                ...loginRequest,
                account: accounts[0],
            })
                .then((response) => {
                    setAccessToken(response.idToken);
            });
        }else{

                    if (!customersFetched) {
                        fetchData('/Customers?goodStanding=true', accessToken).then(data => setCustomers(data),setCustomersFetched(true));
                    }


        if (!loaded && customers.length) {
            if (queryParameters.get("id")) {
                setUpdate(true);
                setOrderId(queryParameters.get("id"));
                const getData = async () => {
                    try {
                        const response = await fetchData(
                            '/Orders/' + queryParameters.get("id"),accessToken
                        );
                        if (!loaded) {
                            let actualData = response;
                            let foundCustomer = customers.find(record => record.customerKey === actualData.customer.customerKey);
                            setTermsText(isFuelOnly === "true"?"Terms: " + foundCustomer.bulkFuelTerms:"Terms: " + foundCustomer.warehouseTerms);
                            setCustomer(foundCustomer);
                            setCustomerInputValue(actualData.customer.customerID + " " + actualData.customer.description);
                            setOrigin(origins.find(record => record.id === actualData.origin).id);
                            setOriginInputValue(actualData.origin.charAt(0).toUpperCase() + actualData.origin.slice(1).toLowerCase());
                            changeLocationOptionBaseOnCustomer(actualData.customer.customerKey,accessToken);
                            setPersistedLocation(actualData.location);
                            setLocationInputValue(actualData.location.address1 +  " | " + actualData.location.city + ", " + actualData.location.state);
                            setDeliveryDate(actualData.deliveryDate);
                            setOrderDate(actualData.orderDate);
                            setOrderItems(actualData.items);
                            setQuote(actualData.quote);
                            setSubmitText(actualData.quote?"Submit Quote":"Submit Order");
                            setOrderNotes(actualData.orderNotes + "");
                            setDeliveryNotes(actualData.deliveryNotes + "");
                            setPoNo(actualData.poNo + "");
                            setIdCounter((actualData.items.length +1));
                            console.log("items: " + actualData.items.length);
                            console.log(actualData);
                            for (let i = 0; i < actualData.items.length; i++) {
                                setRows((prevRows) => [...prevRows, {
                                    id: i+1,
                                    product: actualData.items[i].product.label,
                                    productPackage: ((actualData.items[i].productPackage == null)? "Gallons":actualData.items[i].productPackage.label) ,
                                    quantity: actualData.items[i].quantity,
                                    price: actualData.items[i].price,
                                    totalCost: actualData.items[i].quantity * actualData.items[i].price,
                                    note: actualData.items[i].note,
                                    wasLoaded: true

                                }])
                            }
                        }
                    } catch (err) {
                    } finally {
                    }
                }
                getData();
            }
            if(customers) {
                setLoaded(true);
            }
        }
        if(locations.length && persistedLocation){
            setLocation(persistedLocation);
            setPersistedLocation('')
        }
        }
        }
        , [customers,locations,accessToken]);

    function changeLocationOptionBaseOnCustomer (value,accessToken){
        fetchData('/Locations?customer=' + value,accessToken)
            .then(data => {if(data === undefined || data === null || (data != null && data.length ==0)) {
                setLocations([]);
                setLocation('');
            }else{setLocation('');setLocations(data)}});
    }

        const handleSubmit = (e) => {
        e.preventDefault();

        if(!customer){
            setCustomerErrorText("Please select a customer!");
            return;
        }else{
            setCustomerErrorText("");
        }
        if(!location){
            setLocationErrorText("Please select a location!");
            return;
        }else{
            setLocationErrorText("");
        }
        setSubmitDisabled(true);
        const data = {customer:customer,orderDate: currentDate,origin:origin,deliveryDate:deliveryDate, items: orderItems, quote: quote, orderNotes: orderNotes, deliveryNotes: deliveryNotes,fuelOnly: isFuelOnly,location: location,poNo: poNo}

        updateData("/Orders"+(update?"/"+ orderId:""),accessToken, {
            method: update?"PUT":"POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(() => {
                navigate("/");
            })
            .catch((err) => {
                console.log(err.toString());
            });
    }

    const handleQuoteChange = (event) => {
        setQuote(event.target.checked);
        setSubmitText(event.target.checked?"Submit Quote":"Submit Order");
    };

//    const filterOptions = createFilterOptions({
//        stringify: (option) => option.customer.description
//    });

    const [originInputValue, setOriginInputValue] = React.useState('');
    const [locationInputValue, setLocationInputValue] = React.useState('');

    return (
        <Box
            component="form"
            action={""}
            method={update?"PUT":"POST"}
            onSubmit={handleSubmit}
            sx={{ m: 1}}
            autoComplete="off"
        >
            <input type={"hidden"} name="orderDate" value={orderDate}/>
            <input type={"hidden"} name="isFuelOnly" value={isFuelOnly}/>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Autocomplete
                        disablePortal
                        id="customer"
                        options={customers}
                        value={customer}
                        onChange={(event, value, reason) => {
                            if (reason === 'selectOption') {
                                console.log("Blanking location!");
                                setLocation('');
                                setLocationInputValue('');
                                setTermsText('');
                                changeLocationOptionBaseOnCustomer(value.customerKey,accessToken);
                            }else if(reason === 'clear') {
                                setLocations([]);
                                setTermsText('');
                            }
                            if(value != null && value != ''){
                                setCustomerErrorText('');
                                setCustomer(value);
                                setTermsText(isFuelOnly === "true"?"Terms: " + value.bulkFuelTerms:"Terms: " + value.warehouseTerms);
//                                setCustomerInputValue(value.customerID + " " + value.description)
                                }}
                        }
                        onInputChange={(event, newInputValue) => {
                            setCustomerInputValue(newInputValue);
                        }}
                        getOptionLabel={(data) => data?(data.customerID + " " + data.description):""}
                        sx={{fieldStyle}}
                        aria-required={true}
                        renderInput={(params) => <TextField {...params} label="Customer" required={true}/>}
                    />
                </Grid>
                <Grid item xs={3}>
                    Only customers in Good Standing are available.<br/>
                    {termsText}
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        id="location"
                        options={locations}
                        getOptionLabel={(data) => data?(data.address1 +" | " + data.city +", " + data.state):''}
                        sx={{fieldStyle}}
                        value={location}
                        inputValue={locationInputValue}
                        onInputChange={(event, newInputValue) => {
                            setLocationInputValue(newInputValue);
                        }}
                        aria-required={true}
                        onChange={(event, value, reason, details) => {if(value != ''){
                            setCustomerErrorText('');}setLocation(value);}}
                        renderInput={(params) => <TextField {...params} error={!!locationErrorText} helperText={locationErrorText} label="Location" required={true}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        id="origin"
                        options={origins}
                        sx={{fieldStyle}}
                        value={origin}
                        inputValue={originInputValue}
                        aria-required={true}
                        isOptionEqualToValue={(option, value) => option.id === value}
                        onChange={(event, value, reason) => {
                            if (reason === 'selectOption') {
                                setOrigin(value.id);
                            }else if(reason === 'clear') {
                                setOrigin('');
                            }
                            if(value != null && value != ''){
                                setOriginErrorText('');
                                console.log(value);setOrigin(value.id);}}
                        }
                        renderInput={(params) => <TextField {...params} error={!!originErrorText} helperText={originErrorText} label="Origin" required={true} />}
                        onInputChange={(event, newInputValue,reason) => {
                            if(origins.find((record => record.id === newInputValue))){
                            }else {
                                if (event != null && reason != null) {
                                    setOriginInputValue(newInputValue);
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item  xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker  sx={{fieldStyle}} xs={12} label="Delivery Date" minDate={tomorrow} value={tomorrow} onChange={(newValue) => setDeliveryDate(newValue)}/>
                    </LocalizationProvider>
                </Grid>
                <Grid item  xs={6}>
                    <span style={{color:'red'}}>
                        {isFuelOnly === "true"?"FUEL ONLY ORDER":""}
                    </span>
                </Grid>
                <Grid item  xs={12}>
                    <TextField xs={12} sx={fieldStyleDoubleWidth} id="orderNotes" label="Order Notes" multiline rows={4} type="text" inputProps={{ maxLength: 255 }} variant="outlined"   value={orderNotes}
                               onChange={(event) => {
                                   setOrderNotes(event.target.value);
                               }}/>
                </Grid>
                <Grid item  xs={12}>
                    <TextField xs={12} sx={fieldStyleDoubleWidth} id="deliveryNotes" label="Delivery Notes" multiline rows={4} type="text" inputProps={{ maxLength: 255 }} variant="outlined"   value={deliveryNotes}
                               onChange={(event) => {
                                   setDeliveryNotes(event.target.value);
                               }}/>
                </Grid>
                <Grid item  xs={12}>
                    <TextField xs={12} sx={fieldStyleDoubleWidth} id="po_no" label="PO Number" type="text" variant="outlined" inputProps={{ maxLength: 20 }}  value={poNo}
                               onChange={(event) => {
                                   setPoNo(event.target.value);
                               }}/>
                </Grid>
                <Grid item  xs={12}>
                    <div style={{ height: 350, width: '100%' }}>
                        <DataGrid editMode="row" rows={rows} columns={columns} processRowUpdate={(updatedRow, originalRow) =>
                            processUpdatedRow(updatedRow,originalRow) } onProcessRowUpdateError={handleProcessRowUpdateError} isCellEditable={(params) => !params.row.wasLoaded} columnVisibilityModel={{wasLoaded:false}} />
                        <AddProduct sx={{ alignItems: 'center' }} parentCallback={handleAddRow} isFuelOnly={isFuelOnly} />
                    </div>
                </Grid>
                <Grid item  xs={12}>
                    <FormControlLabel labelPlacement="start" label="Quote?" control={<Checkbox style={{ width:"100px"}} xs={6} sx={fieldStyleDoubleWidth} id="quote" {...quoteLabel} checked={quote} onChange={handleQuoteChange}/>} />
                </Grid>
                <Grid item xs={3}>
                    <Button type={"submit"}  variant="contained" onClick={handleSubmit} disabled={submitDisabled}>{submitText}</Button>
                </Grid>
            </Grid>
        </Box>
    );

}

