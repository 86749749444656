import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {SignOutButton} from "./SignOutButton";
import {SignInButton} from "./SignInButton";
import {useIsAuthenticated} from "@azure/msal-react";
import {Link} from "react-router-dom";
import {Grid} from "@mui/material";

export default function Appbar() {
    const isAuthenticated = useIsAuthenticated();
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>

                    <a href={'/'}>
                    <img
                        alt="Logo"
                        src="logo512.png"
                        height="54"
                    />
                    </a>
                    <Grid container justifyContent="flex-end">
                        {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                    </Grid>

                </Toolbar>
            </AppBar>
        </Box>
    );
}