import React, {Component, useState} from 'react';
import './App.css';
import AppBar from './components/AppBar.js';
import {createTheme, ThemeProvider} from "@mui/material";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import Home from "./pages/home";
import AddOrder from "./pages/addOrder";
import Login from "./components/Login";
import EditOrder from "./pages/editOrder";
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import { ProfileData } from './components/ProfileData';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication} from '@azure/msal-react';
import Button from '@mui/material/Button';
import {InteractionType} from "@azure/msal-browser";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                callMsGraph(response.accessToken).then((response) => setGraphData(response));
            });
    }

    return (
        <>
            <h5 className="card-title">Welcome {accounts[0].name}</h5>
            <br/>
        </>
    );
};

const theme = createTheme({
    palette: {
        primary: {
            main: "#2a9461"
        }
    }
});

function App() {
    useMsalAuthentication(InteractionType.Redirect);
          return ( <div className="App">
              <ThemeProvider theme={theme}>
                  <AppBar/>
              <UnauthenticatedTemplate>
              <h5>
                  <center>
                      Please sign-in to see your profile information.
                  </center>
              </h5>
          </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
            <ProfileContent/>
            <Router>
                <Routes>
                <Route exact path="/" element={<Home />}  />
                <Route path="/AddOrder" element={<AddOrder/>} />
                <Route path="/EditOrder" element={<EditOrder/>} />
                </Routes>
            </Router>
        </AuthenticatedTemplate>
              </ThemeProvider>
        </div>
    );
  }
export default App;
