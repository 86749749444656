import {createContext, useContext, useEffect, useState} from "react";

const Context = createContext(null);

export const ProductsDataProvider = ({ children }) => {
    const [products, setProducts] = useState();


    return (
        <Context.Provider value={products}>
            {children}
        </Context.Provider>
    )
}

export const useProducts = () => useContext(Context);