import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useProducts} from "./ProductsDataProvider";
import {
    FormControl,
    FormHelperText,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    setRef,
    TextareaAutosize
} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {useMsal, useMsalAuthentication} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {fetchData} from "../fetch";
import {loginRequest} from "../authConfig";

const style = {
    position: 'absolute',
    top: '25%',
    left: '25%',
    transform: 'translate(-25%, -25%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    rowSpacing :2
};

const fieldStyle = {
    my:2,
    width: 300
}

export default function AddProduct(props) {
    const isFuelOnly = props.isFuelOnly;
    const parentCallback = props.parentCallback;
    const [product, setProduct] = useState('');
    const [quantity, setQuantity] = useState({value:"",error:""});
    const note = useRef('');
    const [accessToken, setAccessToken] = useState();
    const [open, setOpen] = useState(false);
    const [pack, setPackage] = useState('');
    const [packageInputValue, setPackageInputValue] = useState('');
    const [productError, setProductError] = useState('');
    const [packageError, setPackageError] = useState('');
    const [packageOptions, setPackageOptions] = useState([]);
    const [products, setProducts] = useState([]);
    const { instance, accounts } = useMsal();
    const [currency, setCurrency] = useState({value:"",error:""});
    const [addDisabled, setAddDisabled] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setPackageOptions([]);
        setPackage('');
        setOpen(false);
    }

    const handleSubmit = e => {
        e.preventDefault();
        console.log(e);
    };
    function changePackageOptionBaseOnValue (value,accessToken){
        fetchData('/ProductType?product=' + encodeURIComponent(value),accessToken)
            .then(data => {console.log(data);if(data === undefined || data === null || (data != null && data.length ==0)) {
            setPackageOptions([{value:'-1',label:'Gallons'}]);
            //setPackage({value:'-1',label:'Gallons'});
            setPackageInputValue('Gallons');
        }else{setPackageInputValue('');setPackage('');setPackageOptions(data)}});
    }

    useEffect(() =>{
        console.log("Fuel Only is: "+ isFuelOnly);
        if(accessToken == null){
            instance.acquireTokenSilent({
                forceRefresh: true,
                ...loginRequest,
                account: accounts[0],
            })
                .then((response) => {
                    setAccessToken(response.idToken);
                });
        }else {
            const asyncFn = async () => {
                fetchData('/Products' + ((isFuelOnly === "true") ? '?isFuelOnly=true' : ''), accessToken).then(data => setProducts(data));
            };
            asyncFn();
        }
    }, [accessToken]);

    return (
        <div>
            <Box sx={{m:1}} textAlign="center">
            <Button variant="contained" size="small" onClick={handleOpen}>
                Add a product
            </Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Autocomplete
                        disablePortal
                        id="product"
                        options={products}
                        getOptionLabel={(data) => data.value + " - " + data.label}
                        onChange={(event, value, reason) => {
                            if (reason === 'selectOption') {
                                if(product != value){
                                    setPackage('');
                                    setPackageInputValue('')
                                }
                                setProduct(value);
                                changePackageOptionBaseOnValue(value.value,accessToken);
                                setProductError("");
                            }else if(reason === 'clear'){
                                setPackage('');
                                setPackageInputValue('')
                                setPackageOptions([]);
                                setProductError("Product is required.");
                            }
                            setAddDisabled((currency.value.length == 0 || value == null || value.value.length == 0 || quantity.value.length == 0 || pack == null || pack.length == 0));
                        }}
                        renderInput={(params) => <TextField {...params} label="Product" required error={!!productError} />}
                        sx={fieldStyle}
                    />
                    {!!productError && (
                        <FormHelperText error id="product-error">
                            {productError}
                        </FormHelperText>
                    )}
                    <Autocomplete
                        disablePortal
                        id="package"
                        options={packageOptions}
                        getOptionLabel={(data) => data.label}
                        renderInput={(params) => <TextField {...params} label="Package" required error={!!packageError}/>}
                        onChange={(event, value, reason) => {
                            console.log("PACKAGE CHANGE");
                            console.log(isFuelOnly);
                            if(!!isFuelOnly) {
                                console.log("Setting package " + value);
                                setPackage(value);
                            }
                            if(reason === 'clear'){
                                setPackageError("Package is required.");
                            }
                            if(value != null){
                                setPackageError("");
                            }
                            setAddDisabled((currency.value.length == 0 || value == null || value.value.length == 0 || quantity.value.length == 0 || product.length == 0));
                        }}
                        inputValue={packageInputValue}
                        onInputChange={(event, newInputValue) => {
                            setPackageInputValue(newInputValue);
                        }}
                        sx={fieldStyle}
                    />
                    {!!packageError && (
                        <FormHelperText error id="package-error">
                            {packageError}
                        </FormHelperText>
                    )}
                    <FormControl sx={fieldStyle} variant="outlined">
                    <TextField sx={fieldStyle} id="quantity" label="Quantity" type="number" variant="outlined"
                               error={!!quantity.error}
                               onChange={(event) => {setQuantity({
                                   value: event.target.value,
                                   error: (event.target.value.indexOf(".") >=0)?"Please enter a whole number without decimal.":(event.target.value <= 0)?"Please enter a quantity.":(!!isFuelOnly || (event.target.value <=9000))?"":"Fuel orders may not exceed 9000."
                               });
                                   setAddDisabled((currency.value.length == 0 || event.target.value.length == 0 || pack.length == 0 || product.length == 0));
                               }}
                               required InputProps={{ inputProps:{min:1,max: isFuelOnly?9000:10000000}}}/>
                        {!!quantity.error && (
                            <FormHelperText error id="quantity-error">
                                {quantity.error}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl sx={fieldStyle} variant="outlined">
                        <InputLabel htmlFor="unitCost">Unit Price *</InputLabel>
                        <OutlinedInput
                            id="unitCost"
                            startAdornment={<InputAdornment position="start">$</InputAdornment> }
                            label="Unit Price"
                            inputProps={{inputMode:'decimal',pattern:'^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$'}}
                            error={!!currency.error}
                            onChange={(event) => {setCurrency({
                                value: event.target.value,
                                error: /^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/.test(event.target.value)?((event.target.value >= 0)?"":"Please enter a price."):"Please enter a valid currency."
                            })
                                setAddDisabled((product.length == 0 || event.target.value.length == 0 || quantity.value.length == 0 || product.length == 0));

                            }}
                        />
                        {!!currency.error && (
                            <FormHelperText error id="unitCost-error">
                                {currency.error}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <TextField sx={fieldStyle} id="note" label="Notes" multiline rows={4} inputRef={(c) =>{setRef(note,c)}} type="string" variant="outlined"/>
                    <Button
                        disabled={addDisabled}
                        variant="contained"
                        sx={fieldStyle}
                        onClick={() => {parentCallback(currency.value,pack,product,quantity.value,note.current.value,handleClose)}}
                    >Add to Order</Button>
                    <Button
                        variant="contained"
                        sx={fieldStyle}
                        onClick={() => {handleClose()}}
                    >Cancel</Button>
                </Box>
            </Modal>
        </div>
    );
}