import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param endpoint
 * @param accessToken
 */
export const  fetchData = (endpoint, accessToken) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
console.log("FETCHING: " + endpoint);
    return fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, options)
        .then(response => response.json())
        .catch(error => {console.log(error);
        if(error instanceof  TypeError){
          console.log(error.message);
        }});
}

export const  updateData = (endpoint, accessToken,options) => {
    const bearer = `Bearer ${accessToken}`;

    options.headers.Authorization = bearer;

    return fetch(process.env.REACT_APP_API_ENDPOINT + endpoint, options)
        .then(response => response)
        .catch(error => console.log(error));
}